<template>
    <ValidationObserver v-slot="{ handleSubmit }">
        <form id="seller-register-form" @submit.prevent="(e) => handleSubmit(() => submitRegister(e))"
            class="collaboration-register container fontsize12">
            <div class="collaboration-register-grid">
                <ValidationProvider name="name" mode="passive" class="mb-3 d-flex flex-column text-right"
                    rules="required" v-slot="v">
                    <label for="" class="fontsize13 text-color-444 pr-2">نام :</label>
                    <input :disabled="sendingRequest" name="name" @change="v.validate" type="text" class="bg-white p-2 border-radius7 border"
                        placeholder="نام خود را وارد کنید">
                    <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider name="last_name" mode="passive" class="mb-3 d-flex flex-column text-right"
                    rules="required" v-slot="v">
                    <label for="" class="fontsize13 text-color-444 pr-2">نام خانوادگی :</label>
                    <input :disabled="sendingRequest" name="last_name" @change="v.validate" type="text" class="bg-white p-2 border-radius7 border"
                        placeholder="نام خانوادگی خود را وارد کنید">
                    <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-number name="phone_number" mode="passive"
                    class="mb-3 d-flex flex-column text-right" rules="required" v-slot="v">
                    <label for="" class="fontsize13 text-color-444 pr-2">شماره تماس :</label>
                    <input :disabled="sendingRequest" name="phone_number" @change="v.validate" type="text"
                        class="bg-white p-2 border-radius7 border" placeholder="شماره تماس خود را وارد کنید">
                    <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider name="username" mode="passive" class="mb-3 d-flex flex-column text-right"
                    rules="required" v-slot="v">
                    <label for="" class="fontsize13 text-color-444 pr-2">نام کاربری :</label>
                    <input :disabled="sendingRequest" name="username" @change="v.validate" type="text" class="bg-white p-2 border-radius7 border"
                        placeholder="نام کاربری خود را وارد کنید">
                    <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider name="password" mode="passive" class="mb-3 d-flex flex-column text-right"
                    rules="required" v-slot="v">
                    <label for="" class="fontsize13 text-color-444 pr-2">کلمه عبور :</label>
                    <input :disabled="sendingRequest" name="password" @change="v.validate" type="password"
                        class="bg-white p-2 border-radius7 border" placeholder="کلمه عبور خود را وارد کنید">
                    <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider name="email" mode="passive" class="mb-3 d-flex flex-column text-right"
                    rules="required" v-slot="v">
                    <label for="" class="fontsize13 text-color-444 pr-2">آدرس ایمیل :</label>
                    <input :disabled="sendingRequest" name="email" @change="v.validate" type="email" class="bg-white p-2 border-radius7 border"
                        placeholder="ایمیل خود را وارد کنید">
                    <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider name="company_name" mode="passive" class="mb-3 d-flex flex-column text-right"
                    rules="required" v-slot="v">
                    <label for="" class="fontsize13 text-color-444 pr-2">نام شرکت :</label>
                    <input :disabled="sendingRequest" name="company_name" @change="v.validate" type="text"
                        class="bg-white p-2 border-radius7 border" placeholder="نام شرکت خود را وارد کنید">
                    <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider name="ownership" mode="passive" class="mb-3 d-flex flex-column text-right"
                    rules="required" v-slot="v">
                    <label for="" class="fontsize13 text-color-444 pr-2">نوع مالکیت :</label>
                    <div class="variety-title d-flex align-items-center">
                        <multiselect :disabled="sendingRequest" :allow-empty="false" class="text-right" v-model="selected_ownership"
                            :options="ownershipOptions" track-by="value" label="title" :searchable="false"
                            :close-on-select="true" :show-labels="false" placeholder="نوع مالکیت خود را وارد کنید">
                        </multiselect>
                    </div>
                    <input name="ownership" @change="v.validate" type="hidden"
                        :value="selected_ownership != null ? selected_ownership.value : ''">
                    <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-if="categories != ''" name="categories" mode="passive" class="mb-3 d-flex flex-column text-right"
                    rules="required" v-slot="v">
                    <label for="" class="fontsize13 text-color-444 pr-2">دسته بندی کالا :</label>
                    <div class="variety-title ">
                        <multiselect :disabled="sendingRequest" class="text-right" v-model="selected_category" :options="categories" track-by="id"
                            label="name" :searchable="true" :multiple="true" :close-on-select="true"
                            :show-labels="false" placeholder="دسته بندی کالا خود را وارد کنید">
                        </multiselect>
                    </div>
                    <!-- <input name="categories" @change="v.validate" type="hidden"
                        :value="selected_category != null ? selected_category : ''"> -->
                    <div v-if="selected_category != null">
                        <input v-for="item in selected_category" :key="item.id" name="categories[]" @change="v.validate"
                            type="hidden" :value="item.id">
                    </div>
                    <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider name="province" mode="passive" class="mb-3 d-flex flex-column text-right"
                    rules="required" v-slot="v">
                    <label for="" class="fontsize13 text-color-444 pr-2">استان :</label>
                    <multiselect :disabled="sendingRequest" v-if="provinces" class="text-right" v-model="selected_province" :options="provinces"
                        track-by="id" label="name" :searchable="true" :close-on-select="true" :show-labels="false"
                        placeholder="استان را انتخاب کنید (الزامی)" @input="loadCities">
                    </multiselect>
                    <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                </ValidationProvider>
                <transition name="fade">
                    <template v-if="selected_province != null">
                        <ValidationProvider name="city_id" mode="passive" class="mb-3 d-flex flex-column text-right"
                            rules="required" v-slot="v">
                            <label for="" class="fontsize13 text-color-444 pr-2">شهر :</label>
                            <div class="variety-title d-flex align-items-center">
                                <multiselect :disabled="sendingRequest" class="text-right" v-model="city_id" :options="cities" track-by="id"
                                    label="name" :searchable="true" :close-on-select="true" :show-labels="false"
                                    placeholder="شهر را انتخاب کنید (الزامی)">
                                </multiselect>
                            </div>
                            <input name="city_id" @change="v.validate" type="hidden"
                                :value="city_id != null ? city_id.id : ''">
                            <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                    </template>

                </transition>

                <ValidationProvider name="product_varieties_count" mode="passive"
                    class="mb-3 d-flex flex-column text-right" rules="required" v-slot="v">
                    <label for="" class="fontsize13 text-color-444 pr-2">تنوع کالایی :</label>
                    <div class="variety-title d-flex align-items-center">
                        <multiselect :disabled="sendingRequest" :allow-empty="false" class="text-right" v-model="selected_product_varieties_count"
                            :options="productVarietiesCountOptions" track-by="value" label="title" :searchable="false"
                            :close-on-select="true" :show-labels="false" placeholder="تنوع کالایی خود را وارد کنید">
                        </multiselect>
                    </div>
                    <input name="product_varieties_count" @change="v.validate" type="hidden"
                        :value="selected_product_varieties_count != null ? selected_product_varieties_count.value : ''">
                    <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                </ValidationProvider>
                <div class="mb-3 d-flex flex-column text-right register-file">
                    <label for="" class="fontsize13 text-color-444 pr-2">تصویر جواز :</label>
                    <b-form-file :disabled="sendingRequest" class="text-color-666" placeholder="تصویر خود را انتخاب کنید" name="license_image"
                        accept="image/*">
                    </b-form-file>
                </div>
            </div>
            <div class=" text-center">
                <button :disabled="sendingRequest" type="submit" class="border-radius7 mt-2 fontsize14 px-5 p-2 bg-dark text-white">
                    ارسال درخواست همکاری
                </button>
            </div>
        </form>
    </ValidationObserver>
</template>
<script>
    import {
        ValidationObserver,
        ValidationProvider
    } from "vee-validate";

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },
        props:[
            'categories',
            'provinces'
        ],
        data() {
            return {
                ownershipOptions: [{
                        value: 'legal',
                        title: 'حقوقی'
                    },
                    {
                        value: 'character',
                        title: 'حقیقی'
                    },
                ],
                productVarietiesCountOptions: [{
                        value: 'zero_to_fifty',
                        title: 'صفر تا 50'
                    },
                    {
                        value: 'fifty_to_hundred',
                        title: '50 تا 100'
                    },
                    {
                        value: 'higher_than_hundred',
                        title: '100 به بالا'
                    },
                ],
                selected_ownership: null,
                selected_category: null,
                selected_province: null,
                city_id: null,
                selected_product_varieties_count: null,
                cities: [],
                sendingRequest: false
            }
        },
        mounted() {
        },
        methods: {
            loadCities(event) {
                this.cities = event.cities
            },
            submitRegister(e) {
                this.sendingRequest = true;
                let formData = new FormData(e.target)
                this.$axios.post(`/api/seller/register`, formData)
                    .then((response) => {
                        this.$root.success_notification(response.data.message)
                        this.resetForm();
                        this.selected_category = null
                        this.selected_province = null
                        this.selected_product_varieties_count = null
                        this.selected_ownership = null
                        this.city_id = null
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.sendingRequest = false
                    });
            },
            resetForm() {
                this.$('form#seller-register-form input').val('');
            },
        }
    }
</script>
<style>
    #seller-register-form .multiselect__tags {
        min-height: 37px;
    }

    #seller-register-form .multiselect,
    #seller-register-form .multiselect__input,
    #seller-register-form .multiselect__single,
    #seller-register-form .multiselect__tags {
        font-size: 12px !important;
    }

    #seller-register-form .multiselect__option--highlight::after,
    #seller-register-form .multiselect__option--highlight,
    #seller-register-form .multiselect__tag {
        background: var(--color-themeBlue);
    }

    #seller-register-form .multiselect__option--selected.multiselect__option--highlight::after,
    #seller-register-form .multiselect__option--selected.multiselect__option--highlight,
    #seller-register-form .multiselect__tag-icon:hover {
        background: var(--color-themeBlue-i) !important;
    }

    .register-file .custom-file-label::after {
        right: unset;
        left: 0;
        border-radius: 0.25rem 0 0 0.25rem;
    }

    .register-file .custom-file-input {
        height: 37px !important;
    }
</style>