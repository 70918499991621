<template>
    <div class="card mb-2 " v-if="faq != null">
        <div class="card-header text-right" :id="'heading' + faq.id">
            <h6 class="mb-0">
                <button class="btn btn-link btn-block text-right btnCollapse" type="button" data-toggle="collapse"
                    :data-target="'#faq' + faq.id" :aria-expanded="index == 0 ? true : false" :aria-controls="'faq' + faq.id">
                    <span class="px-2 rounded bg-color-ea">{{index + 1}}</span>
                   {{faq.question}}
                    <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.869 16.6308C10.811 16.5743 10.563 16.3609 10.359 16.1622C9.076 14.9971 6.976 11.9576 6.335 10.3668C6.232 10.1252 6.014 9.51437 6 9.18802C6 8.8753 6.072 8.5772 6.218 8.29274C6.422 7.93814 6.743 7.65368 7.122 7.49781C7.385 7.39747 8.172 7.2416 8.186 7.2416C9.047 7.08573 10.446 7 11.992 7C13.465 7 14.807 7.08573 15.681 7.21335C15.695 7.22796 16.673 7.38383 17.008 7.55431C17.62 7.86702 18 8.47784 18 9.13151V9.18802C17.985 9.61374 17.605 10.509 17.591 10.509C16.949 12.0141 14.952 14.9834 13.625 16.1768C13.625 16.1768 13.284 16.5129 13.071 16.659C12.765 16.887 12.386 17 12.007 17C11.584 17 11.19 16.8724 10.869 16.6308Z"
                            fill="#e0e0e0" />
                    </svg>
                </button>
            </h6>
        </div>

        <div :id="'faq' + faq.id" class="collapse" :class="index == 0 ? 'show' : ''" :aria-labelledby="'heading' + faq.id" :data-parent="'#accordion' + categoryId">
            <div class="card-body line-height2 descriptionCollapse">
               {{faq.answer}}
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'faq',
            'index',
            'categoryId'
        ]
    }
</script>
<style>
.btnCollapse{
    font-weight: bold;
}
.card-body.descriptionCollapse{
    font-size: 17px !important;
}
</style>