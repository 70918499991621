<template>
    <div class="in-seller">
        <sellerHeader />
        <main id="seller-header">
            <section id="seller-start" class="online-shop-start d-flex mb-md-5 mb-0">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 d-md-block d-none">
                            <!-- <img src="../../assets/images/online shop pic2.png" class="w-100" alt=""> -->
                        </div>
                        <div class="col-md-6 align-self-end text-right">
                            <h5 class="text-color-444 weight-bold">فرآیند شروع <span class="text-color-themeBlue weight-bold">همکاری</span>  در آلبا </h5>
                            <p class="fontsize12 text-color-999">The process of starting a collaboretion with alba market</p>
                            <div class="border-radius15 overflow-hidden">
                                <div class="d-flex bg-color-ea px-3 pt-3">
                                    <span class="ml-2">
                                        <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5495 13.73H14.2624C14.6683 13.73 15.005 13.4 15.005 12.99C15.005 12.57 14.6683 12.24 14.2624 12.24H12.5495V10.51C12.5495 10.1 12.2228 9.77 11.8168 9.77C11.4109 9.77 11.0743 10.1 11.0743 10.51V12.24H9.37129C8.96535 12.24 8.62871 12.57 8.62871 12.99C8.62871 13.4 8.96535 13.73 9.37129 13.73H11.0743V15.46C11.0743 15.87 11.4109 16.2 11.8168 16.2C12.2228 16.2 12.5495 15.87 12.5495 15.46V13.73ZM19.3381 9.02561C19.5708 9.02292 19.8242 9.02 20.0545 9.02C20.302 9.02 20.5 9.22 20.5 9.47V17.51C20.5 19.99 18.5099 22 16.0446 22H8.17327C5.59901 22 3.5 19.89 3.5 17.29V6.51C3.5 4.03 5.5 2 7.96535 2H13.2525C13.5099 2 13.7079 2.21 13.7079 2.46V5.68C13.7079 7.51 15.203 9.01 17.0149 9.02C17.4381 9.02 17.8112 9.02316 18.1377 9.02593C18.3917 9.02809 18.6175 9.03 18.8168 9.03C18.9578 9.03 19.1405 9.02789 19.3381 9.02561ZM19.61 7.5662C18.7961 7.5692 17.8367 7.5662 17.1466 7.5592C16.0516 7.5592 15.1496 6.6482 15.1496 5.5422V2.9062C15.1496 2.4752 15.6674 2.2612 15.9635 2.5722C16.4995 3.1351 17.2361 3.90891 17.9693 4.67913C18.7002 5.44689 19.4277 6.21108 19.9496 6.7592C20.2387 7.0622 20.0268 7.5652 19.61 7.5662Z" fill="#444"/>
                                        </svg>
                                    </span>
                                    <div>
                                        <button class="bg-none fontsize12 font-weight-bold text-color-444">ثبت درخواست</button>
                                         <p class="fontsize11 text-color-666">فرم درخواست پایین صفحه را تکمیل کنید.</p>
                                    </div>
                                </div>
                                <div class="d-flex bg-color-fa px-3 pt-3">
                                    <span class="ml-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-headphones" viewBox="0 0 16 16">
                                            <path d="M8 3a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a6 6 0 1 1 12 0v5a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1V8a5 5 0 0 0-5-5z" stroke="#444" fill="#444"/>
                                          </svg>
                                    </span>
                                    <div>
                                        <button class="bg-none fontsize12 font-weight-bold text-color-444"> تماس کارشناس و دریافت اطلاعات و مدارک</button>
                                         <p class="fontsize11 text-color-666">کارشناسان ما در آلبا  با شما تماس می گیرند و مدارک را جهت تکمیل ثبت نام دریافت می کنند.</p>
                                    </div>
                                </div>
                                <div class="d-flex bg-color-ea px-3 pt-3">
                                    <span class="ml-2">
                                        <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.67012 1.99991H16.3401C19.7301 1.99991 22.0001 4.37991 22.0001 7.91991V16.0909C22.0001 19.6199 19.7301 21.9999 16.3401 21.9999H7.67012C4.28012 21.9999 2.00012 19.6199 2.00012 16.0909V7.91991C2.00012 4.37991 4.28012 1.99991 7.67012 1.99991ZM11.4301 14.9899L16.1801 10.2399C16.5201 9.89991 16.5201 9.34991 16.1801 8.99991C15.8401 8.65991 15.2801 8.65991 14.9401 8.99991L10.8101 13.1299L9.06012 11.3799C8.72012 11.0399 8.16012 11.0399 7.82012 11.3799C7.48012 11.7199 7.48012 12.2699 7.82012 12.6199L10.2001 14.9899C10.3701 15.1599 10.5901 15.2399 10.8101 15.2399C11.0401 15.2399 11.2601 15.1599 11.4301 14.9899Z" fill="#444"/>
                                        </svg>
                                    </span>
                                    <div>
                                        <button class="bg-none fontsize12 font-weight-bold text-color-444"> تایید اطلاعات و مدارک</button>
                                         <p class="fontsize11 text-color-666">مدارک و اطلاعات شما در آلبا  بررسی و تایید می شوند.</p>
                                    </div>
                                </div>
                                <div class="d-flex bg-color-fa px-3 pt-3">
                                    <span class="ml-2">
                                        <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4184 6.47H16.6232C19.3152 6.47 21.5 8.72 21.5 11.48V17C21.5 19.76 19.3152 22 16.6232 22H7.3768C4.6848 22 2.5 19.76 2.5 17V11.48C2.5 8.72 4.6848 6.47 7.3768 6.47H7.58162C7.60113 5.27 8.05955 4.15 8.8886 3.31C9.72741 2.46 10.8003 2.03 12.0098 2C14.4286 2 16.3891 4 16.4184 6.47ZM9.91273 4.38C9.36653 4.94 9.06417 5.68 9.04466 6.47H14.9553C14.9261 4.83 13.6191 3.5 12.0098 3.5C11.2587 3.5 10.4784 3.81 9.91273 4.38ZM15.7064 10.32C16.116 10.32 16.4379 9.98 16.4379 9.57V8.41C16.4379 8 16.116 7.66 15.7064 7.66C15.3065 7.66 14.9748 8 14.9748 8.41V9.57C14.9748 9.98 15.3065 10.32 15.7064 10.32ZM8.93737 9.57C8.93737 9.98 8.6155 10.32 8.20585 10.32C7.80595 10.32 7.47433 9.98 7.47433 9.57V8.41C7.47433 8 7.80595 7.66 8.20585 7.66C8.6155 7.66 8.93737 8 8.93737 8.41V9.57Z" fill="#444"/>
                                            </svg>
                                    </span>
                                    <div>
                                        <button class="bg-none fontsize12 font-weight-bold text-color-444"> شروع فروش در آلبا </button>
                                         <p class="fontsize11 text-color-666">شما اکنون یک فروشنده حرفه ای در آلبا  هستید.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
            <section  class="online-shop-register text-center mb-1">
                <div id="seller-register" class="container pt-1">
                    <h5 class="text-color-444 weight-bold mt-5">ثبت درخواست    <span class="text-color-themeBlue weight-bold">همکاری</span>  در آلبا </h5>
                    <p class="fontsize13 text-color-999">submit a collaboration request of alba market</p>
                    <p class="fontsize12 text-color-666 line-height2"> آلبا  بستری مناسب برای تمام افراد در حوزه مد وزیبایی می باشد و می توانید بصورت رایگان در این بستر فروشگاه خودرا راه اندازی کنید </p>
                </div>

                <div class=" container my-4">
                    <div class="row">
                        <div class="col-md-4 mb-md-0 mb-2 ">
                            <div class="bg-color-themeBlue border-radius15 p-md-4 py-3 text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                                    <path stroke="#fff" d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" fill="#fff"/>
                                    <path stroke="#fff" d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" fill="#fff"/>
                                </svg>
                                <span class="fontsize13 mx-1">صفحه اینستاگرام:</span>
                                <a style="color: white" target="_blank" href="https://instagram.com/linoose.market" class="fontsize12">linoose.market@</a>
                            </div>
                        </div>
                        <div class="col-md-4 mb-md-0 mb-2">
                            <div class="bg-color-themeBlue border-radius15 p-md-4 py-3 text-white">
                                <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9394 3C18.2804 3 19.5704 3.53 20.5194 4.481C21.4694 5.43 22.0004 6.71 22.0004 8.05V15.95C22.0004 18.74 19.7304 21 16.9394 21H7.0604C4.2694 21 2.0004 18.74 2.0004 15.95V8.05C2.0004 5.26 4.2594 3 7.0604 3H16.9394ZM18.5304 9.54L18.6104 9.46C18.8494 9.17 18.8494 8.75 18.5994 8.46C18.4604 8.311 18.2694 8.22 18.0704 8.2C17.8604 8.189 17.6604 8.26 17.5094 8.4L13.0004 12C12.4204 12.481 11.5894 12.481 11.0004 12L6.5004 8.4C6.1894 8.17 5.7594 8.2 5.5004 8.47C5.2304 8.74 5.2004 9.17 5.4294 9.47L5.5604 9.6L10.1104 13.15C10.6704 13.59 11.3494 13.83 12.0604 13.83C12.7694 13.83 13.4604 13.59 14.0194 13.15L18.5304 9.54Z" fill="#fff"/>
                                </svg>
                                <span class="fontsize13 mx-1">آدرس  ایمیل:</span>
                                <span class="fontsize12">linoose.sup@gmail.com</span>
                            </div>
                        </div>
                        <div class="col-md-4 mb-md-0 mb-2">
                            <div class="bg-color-themeBlue border-radius15 p-md-4 py-3 text-white">
                                <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3558 2.00793C14.1328 1.97595 13.9087 2.04191 13.7304 2.18381C13.5472 2.32771 13.4326 2.53557 13.4077 2.76841C13.355 3.23908 13.6946 3.66479 14.1646 3.71776C17.4063 4.07951 19.9259 6.60477 20.2904 9.85654C20.3392 10.2922 20.7047 10.621 21.1409 10.621C21.1738 10.621 21.2057 10.619 21.2385 10.615C21.4666 10.59 21.6697 10.4771 21.8132 10.2972C21.9556 10.1174 22.0203 9.89351 21.9944 9.66467C21.5403 5.60746 18.4002 2.45862 14.3558 2.00793ZM14.4181 5.48994C13.942 5.402 13.5048 5.70579 13.4142 6.17047C13.3236 6.63515 13.6283 7.08884 14.0914 7.17978C15.4857 7.45159 16.5623 8.53085 16.8351 9.92989V9.93089C16.9128 10.3336 17.2674 10.6264 17.6757 10.6264C17.7305 10.6264 17.7852 10.6214 17.841 10.6114C18.3041 10.5185 18.6088 10.0658 18.5182 9.60012C18.1109 7.51055 16.5025 5.89666 14.4181 5.48994ZM16.0001 15.0905C16.4515 14.8302 16.9615 14.5362 17.6047 14.673C18.1873 14.7959 20.1731 16.4078 20.7169 16.9665C21.0734 17.3322 21.2716 17.7099 21.3025 18.0877C21.3562 19.5697 19.3425 21.2615 18.976 21.4724C18.489 21.8231 17.9224 22 17.286 22C16.6357 22 15.9106 21.8151 15.1199 21.4464C10.8296 19.6556 4.27553 13.231 2.54266 8.97395C1.82362 7.38903 1.81864 6.08193 2.53071 5.09961C2.81454 4.63892 4.43288 2.71325 5.88192 2.7742C6.26733 2.80718 6.64179 3.00505 7.00928 3.3648C7.56499 3.90942 9.13653 5.90106 9.25803 6.48565C9.39247 7.13521 9.09769 7.65185 8.83576 8.10554C8.7799 8.20307 8.70463 8.31881 8.62217 8.4456C8.3092 8.92683 7.89264 9.56735 8.04004 9.97626C9.09669 12.5705 11.5376 14.8339 14.133 15.8972C14.5343 16.0431 15.1745 15.6235 15.6542 15.3092C15.7791 15.2273 15.8932 15.1525 15.9893 15.0967L16.0001 15.0905Z" fill="#fff"/>
                                </svg>
                                <span class="fontsize13 mx-1">  شماره تماس:</span>
                                <span class="fontsize12">01732334384</span>
                            </div>
                        </div>

                    </div>

                </div>
                <sellerRegister :categories="!$root.isEmptyObject(items) ? items.categories : []" :provinces="!$root.isEmptyObject(items) ? items.provinces : []" />
            </section>

            <sellerFaq :faqs="!$root.isEmptyObject(items) ? items.faqs : []" />
        </main>
        <sellerFooter />
    </div>
</template>
<script>
    import sellerHeader from './component/seller/sellerHeader.vue'
    import sellerFooter from './component/seller/sellerFooter.vue'
    import sellerRegister from './component/seller/sellerRegister.vue'
    import sellerFaq from './component/seller/sellerFaq.vue'
    export default {
        components: {
            sellerHeader,
            sellerFooter,
            sellerRegister,
            sellerFaq
        },
        data() {
            return {
                items:{}
            }
        },
        created() {
            this.loadSellerLanding()
        },
        methods:{
            loadSellerLanding ()  {
                this.$axios.get('/api/all/seller-landing')
                .then(response => {
                    this.items = response.data.data
                })
            }
        },
      metaInfo() {
        return {
          meta: [
            {
              name: 'description',
              content: 'کالا های خود را در آلبا  به فروش بگذارید'
            },
            {property: 'og:title', content: 'همکاری با آلبا '},
          ]
        }
      }
    }
</script>
<style>

</style>
