<template>
    <header class="header-online-shop">
        <section class="pt-3 text-right d-flex flex-wrap justify-content-between align-items-baseline px-5" style="background: #7570b314;">
            <div class="d-flex mb-3 mr-sm-5-custom">
                <button type="button" id="all-categories"
                    class="resp-menu-btn all-categories bg-color-themeBlue d-lg-none py-2 px-2 ml-3"
                    @click="allCategoriesClose()">
                    <img src="../../../../assets/images/menu-w.png" alt="Menu">
                </button>
                <router-link to="/" class="logo">
                    <img src="../../../../assets/images/albaLogo.png" alt="لوگو لبنوس">
                </router-link>
            </div>

            <section class="all-categories-side bg-white d-lg-none">
                <button type="button" class=" weight-bold mr-4 mt-2 px-2 bg-color-ea text-white rounded"
                    @click="allCategoriesClose()">X</button>
                <ul class="d-flex flex-column fontsize12 list-unstyled text-center p-3">
                    <li class="ml-3 mb-3 ">
                        <router-link to="/" class="text-color-666 font-weight-bold d-block">خانه</router-link>
                    </li>
                    <!-- <li class="ml-3 mb-3 ">
                        <a href="#" class="text-color-666 font-weight-bold d-block">مزایای تیمچه</a>
                    </li> -->
                    <li class="ml-3 mb-3 ">
                        <button @click="scroll('#seller-start')" class="text-color-666 font-weight-bold d-block">فرآیند شروع همکاری</button>

                    </li>
                    <li class="ml-3 mb-3 ">
                        <button @click="scroll('#seller-register')" class="text-color-666 font-weight-bold d-block">ثبت درخواست</button>

                    </li>
                    <!-- <li class="ml-3 mb-3 ">
                        <a href="" class="text-color-666 font-weight-bold d-block">همکاری</a>

                    </li> -->
                    <li class="ml-3 mb-3 ">
                        <button @click="scroll('#seller-faq')" class="text-color-666 font-weight-bold d-block">سوالات متداول</button>
                    </li>
                </ul>
            </section>
            <!--resp menu button-->

            <nav class="main-nav d-lg-block d-none" v-b-scrollspy:seller-header="10">
                <ul class="d-flex fontsize11 list-unstyled">
                    <li class=" d-flex flex-column ml-3 ">
                        <router-link to="/" class="text-color-666 font-weight-bold d-block">خانه</router-link>
                        <svg width="11" height="11" viewBox="0 0 24 24" fill="none" class="mx-auto"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.131 7.36922C13.189 7.42572 13.437 7.63906 13.641 7.8378C14.924 9.00292 17.024 12.0424 17.665 13.6332C17.768 13.8748 17.986 14.4856 18 14.812C18 15.1247 17.928 15.4228 17.782 15.7073C17.578 16.0619 17.257 16.3463 16.878 16.5022C16.615 16.6025 15.828 16.7584 15.814 16.7584C14.953 16.9143 13.554 17 12.008 17C10.535 17 9.193 16.9143 8.319 16.7867C8.305 16.772 7.327 16.6162 6.992 16.4457C6.38 16.133 6 15.5222 6 14.8685V14.812C6.015 14.3863 6.395 13.491 6.409 13.491C7.051 11.9859 9.048 9.01656 10.375 7.82319C10.375 7.82319 10.716 7.48709 10.929 7.34096C11.235 7.11301 11.614 7 11.993 7C12.416 7 12.81 7.12762 13.131 7.36922Z"
                                fill="#7570b3" />
                        </svg>
                    </li>
                    <!-- <li class=" d-flex flex-column ml-3 ">
                        <a href="" class="text-color-666 font-weight-bold d-block">مزایای تیمچه</a>
                        <svg width="11" height="11" viewBox="0 0 24 24" fill="none" class="mx-auto" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.131 7.36922C13.189 7.42572 13.437 7.63906 13.641 7.8378C14.924 9.00292 17.024 12.0424 17.665 13.6332C17.768 13.8748 17.986 14.4856 18 14.812C18 15.1247 17.928 15.4228 17.782 15.7073C17.578 16.0619 17.257 16.3463 16.878 16.5022C16.615 16.6025 15.828 16.7584 15.814 16.7584C14.953 16.9143 13.554 17 12.008 17C10.535 17 9.193 16.9143 8.319 16.7867C8.305 16.772 7.327 16.6162 6.992 16.4457C6.38 16.133 6 15.5222 6 14.8685V14.812C6.015 14.3863 6.395 13.491 6.409 13.491C7.051 11.9859 9.048 9.01656 10.375 7.82319C10.375 7.82319 10.716 7.48709 10.929 7.34096C11.235 7.11301 11.614 7 11.993 7C12.416 7 12.81 7.12762 13.131 7.36922Z" fill="#7570b3"/>
                        </svg>
                    </li> -->
                    <li class=" d-flex flex-column ml-3 ">
                        <button @click="scroll('#seller-start')" class="text-color-666 font-weight-bold d-block">فرآیند شروع
                            همکاری</button>
                        <svg width="11" height="11" viewBox="0 0 24 24" fill="none" class="mx-auto"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.131 7.36922C13.189 7.42572 13.437 7.63906 13.641 7.8378C14.924 9.00292 17.024 12.0424 17.665 13.6332C17.768 13.8748 17.986 14.4856 18 14.812C18 15.1247 17.928 15.4228 17.782 15.7073C17.578 16.0619 17.257 16.3463 16.878 16.5022C16.615 16.6025 15.828 16.7584 15.814 16.7584C14.953 16.9143 13.554 17 12.008 17C10.535 17 9.193 16.9143 8.319 16.7867C8.305 16.772 7.327 16.6162 6.992 16.4457C6.38 16.133 6 15.5222 6 14.8685V14.812C6.015 14.3863 6.395 13.491 6.409 13.491C7.051 11.9859 9.048 9.01656 10.375 7.82319C10.375 7.82319 10.716 7.48709 10.929 7.34096C11.235 7.11301 11.614 7 11.993 7C12.416 7 12.81 7.12762 13.131 7.36922Z"
                                fill="#7570b3" />
                        </svg>
                    </li>
                    <li class=" d-flex flex-column ml-3 ">
                        <button @click="scroll('#seller-register')" class="text-color-666 font-weight-bold d-block">ثبت
                            درخواست</button>
                        <svg width="11" height="11" viewBox="0 0 24 24" fill="none" class="mx-auto"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.131 7.36922C13.189 7.42572 13.437 7.63906 13.641 7.8378C14.924 9.00292 17.024 12.0424 17.665 13.6332C17.768 13.8748 17.986 14.4856 18 14.812C18 15.1247 17.928 15.4228 17.782 15.7073C17.578 16.0619 17.257 16.3463 16.878 16.5022C16.615 16.6025 15.828 16.7584 15.814 16.7584C14.953 16.9143 13.554 17 12.008 17C10.535 17 9.193 16.9143 8.319 16.7867C8.305 16.772 7.327 16.6162 6.992 16.4457C6.38 16.133 6 15.5222 6 14.8685V14.812C6.015 14.3863 6.395 13.491 6.409 13.491C7.051 11.9859 9.048 9.01656 10.375 7.82319C10.375 7.82319 10.716 7.48709 10.929 7.34096C11.235 7.11301 11.614 7 11.993 7C12.416 7 12.81 7.12762 13.131 7.36922Z"
                                fill="#7570b3" />
                        </svg>
                    </li>
                    <!-- <li class=" d-flex flex-column ml-3 ">
                        <a href="" class="text-color-666 font-weight-bold d-block">همکاری</a>
                        <svg width="11" height="11" viewBox="0 0 24 24" fill="none" class="mx-auto" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.131 7.36922C13.189 7.42572 13.437 7.63906 13.641 7.8378C14.924 9.00292 17.024 12.0424 17.665 13.6332C17.768 13.8748 17.986 14.4856 18 14.812C18 15.1247 17.928 15.4228 17.782 15.7073C17.578 16.0619 17.257 16.3463 16.878 16.5022C16.615 16.6025 15.828 16.7584 15.814 16.7584C14.953 16.9143 13.554 17 12.008 17C10.535 17 9.193 16.9143 8.319 16.7867C8.305 16.772 7.327 16.6162 6.992 16.4457C6.38 16.133 6 15.5222 6 14.8685V14.812C6.015 14.3863 6.395 13.491 6.409 13.491C7.051 11.9859 9.048 9.01656 10.375 7.82319C10.375 7.82319 10.716 7.48709 10.929 7.34096C11.235 7.11301 11.614 7 11.993 7C12.416 7 12.81 7.12762 13.131 7.36922Z" fill="#7570b3"/>
                        </svg>
                    </li> -->
                    <li class=" d-flex flex-column ml-3 ">
                        <button @click="scroll('#seller-faq')" class="text-color-666 font-weight-bold d-block">سوالات
                            متداول</button>
                        <svg width="11" height="11" viewBox="0 0 24 24" fill="none" class="mx-auto"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.131 7.36922C13.189 7.42572 13.437 7.63906 13.641 7.8378C14.924 9.00292 17.024 12.0424 17.665 13.6332C17.768 13.8748 17.986 14.4856 18 14.812C18 15.1247 17.928 15.4228 17.782 15.7073C17.578 16.0619 17.257 16.3463 16.878 16.5022C16.615 16.6025 15.828 16.7584 15.814 16.7584C14.953 16.9143 13.554 17 12.008 17C10.535 17 9.193 16.9143 8.319 16.7867C8.305 16.772 7.327 16.6162 6.992 16.4457C6.38 16.133 6 15.5222 6 14.8685V14.812C6.015 14.3863 6.395 13.491 6.409 13.491C7.051 11.9859 9.048 9.01656 10.375 7.82319C10.375 7.82319 10.716 7.48709 10.929 7.34096C11.235 7.11301 11.614 7 11.993 7C12.416 7 12.81 7.12762 13.131 7.36922Z"
                                fill="#7570b3" />
                        </svg>
                    </li>
                </ul>
            </nav>
            <div class="ml-sm-5-custom">
                <!-- <button class="signin-on-shop p-2 bg-none border-radius10 text-color-themeBlue fontsize11">
                    ورود به پنل فروشندگان
                </button> -->
                <button @click="loginModal()" class="bg-color-themeBlue p-2 border-radius10 text-white fontsize11">
                    ورود به پنل فروشندگان
                </button>
            </div>
        </section>
        <section class="online-shop-info d-flex container pt-5 pb-5">
            <div class="col-md-6 text-right align-self-end">
                <h5 class="text-color-444 weight-bold">در فروشگاه آنلاین <span
                        class="text-color-themeBlue weight-bold">آلبا </span> فروشنده شوید</h5>
                <p class="fontsize13 text-color-999">Become a Beller In The Linoose Online Store</p>
                <p class="fontsize12 text-color-666 text-justify line-height2">آلبا  بستری مناسب برای تمام افراد در حوزه مد وزیبایی می باشد و می توانید بصورت رایگان در این بستر فروشگاه خودرا راه اندازی کنید</p>
                <div>
                    <button @click="scroll('#seller-register')" type="button"
                        class="fontsize12 text-white border-radius10 bg-color-themeBlue ml-2 py-2 px-3 shadow-themeblue">
                        شروع همکاری
                    </button>
                    <button @click="scroll('#seller-faq')" type="button" class="bg-none fontsize12 text-color-999 py-2 px-3">
                        <span>اطلاعات بیشتر ...</span>
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.25 12.2743L19.25 12.2743" stroke="#888" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.2998 18.2987L4.2498 12.2747L10.2998 6.24969" stroke="#888" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="col-6 d-md-block d-none">
                <img src="../../../../assets/images/online shop pic.png" class="w-100 h-100" alt="">
            </div>
        </section>
        <sellerLoginModal />
    </header>
</template>
<script>
    import sellerLoginModal from './sellerLoginModal.vue'
    export default {
        components: {
            sellerLoginModal
        },
        data() {
            return {
                name: "",
            }
        },
        methods: {
            scroll(name) {
                this.allCategoriesClose()
                this.name = name
                if (this.name !== "") {
                    event.preventDefault();
                    let name = this.name;
                    this.$('html, body').animate({
                        scrollTop: this.$(name).offset().top
                    }, 800, function () {
                        window.location.name = name;
                    });
                }
            },
            allCategoriesClose() {
                document.getElementById('all-categories').classList.toggle('all-categories-close');
                let hasClassClose = document.getElementById('all-categories').classList.contains(
                    'all-categories-close');
                let catSide = document.getElementsByClassName('all-categories-side')[0];
                // let wrapper = document.getElementsByClassName('wrapper')[0];
                if (hasClassClose) {
                    catSide.style.right = "0px";
                    // if(window.screen.width >= 1200){
                    // wrapper.style.paddingRight="250px";
                    // }
                } else {
                    // if(window.screen.width >= 1200){
                    //     wrapper.style.paddingRight="0";
                    // }
                    catSide.style.right = "-300px";
                }
            },
            loginModal() {
                this.$root.getCookie('sellerAuthorization') ? window.location.href = '/panel' : this.$('.login-modal').modal('show')

            }
        }
    }
</script>
<style scoped>
@media screen and (min-width: 550px){
  .mr-sm-5-custom {
    margin-right: 150px;
  }

  .ml-sm-5-custom {
    margin-left: 150px;
  }
}

</style>
